#root {
  height: 100vh;
  .main_container {
    height: 100%;
  }
  .table {
    overflow-y: scroll;
  }
  .main_container {
    display: flex;
    min-height: 100vh;
  }

  .app-header {
    padding: 0;
    background: linear-gradient(to bottom, #dc3c10, #ff7f50);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* добавляем тень для высветления */
  }

  .app-content {
    margin: 24px 16px;
    padding: 24px;
    min-height: 280px;
    background: #fff;
    border-radius: 8px;
    overflow-y: scroll;
  }
  .logout {
    margin-right: 15px;
    margin-left: auto;
  }
  .search_row {
    gap: 20px;
    margin-bottom: 20px;
  }

  .main_table {
    th,
    td,
    tr {
      padding: 5px;
    }
  }
  .main_table {
    td {
      text-align: center;
    }
    th {
      text-align: center;
    }
  }
  .datepicker_cust {
    display: flex;
    flex: 0 0 100%;
  }
  .create_row {
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .layout_cust {
    background: url("../public/bg.jpg");
  }

  p {
    margin-bottom: 0;
  }
  .ant-layout .ant-layout-sider {
    background: #905c97;
  }
  .main_menu {
    background: #905c97;
  }
}

.form_row {
  justify-content: space-between !important;
  gap: 20px;
}
.date_space {
  width: 100%;
  display: block;
}
.trigger {
  svg {
    fill: #fff;
  }
}
.dest_label {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.mask {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  border-radius: 6px;
  transition: all 0.2s;
}
.mask:focus {
  border-color: #1677ff;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
  background-color: #ffffff;
}
.time {
  p {
    font-weight: bold;
  }
}
.comment {
  span {
    width: 100px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.driver_update {
  width: 15px;
  height: 15px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-top: 9px;
  justify-content: center;
  align-items: center;
  background-color: #fc20b2;
}